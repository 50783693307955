import { createReducer } from '@reduxjs/toolkit';
import {
  getCoTaxationGroups,
  getCoTaxationGroup,
  setCoTaxationGroup,
} from 'features/coTaxationGroup/coTaxationGroupActions';
import { CoTaxationGroup } from 'dto/coTaxationGroup';

export interface CoTaxationGroupState {
  list: CoTaxationGroup[];
  current?: CoTaxationGroup;
}

const initialState: CoTaxationGroupState = {
  list: [],
};

export const coTaxationGroupReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCoTaxationGroup, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getCoTaxationGroups.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(getCoTaxationGroup.fulfilled, (state, action) => {
      state.current = action.payload;
    });
});
