import { Divider, Grid, Typography } from '@mui/material';
import { SelectOwnerField } from 'components/SelectOwnerField';
import {
  FormControl,
  PeriodField,
  RadioGroupField,
  SelectField,
  TextField,
  renderPercentageError,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { SelectCurrencyField } from 'components/SelectCurrencyField';
import { ExcludedDatesField } from 'components/ExcludedDatesField';
import { TicketMinimalCostField } from 'components/TicketMinimalCostField';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { PromotionsField } from 'components/PromotionsField';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';
import { PromotionalDiscountDetailsFormInventoryClassesField } from 'routes/PromotionalDiscounts/PromotionalDiscountDetails/InventoryClassesField';

export const PromotionalDiscountDetailsForm = () => {
  const promotionalDiscount = useSelector(promotionalDiscountSelector);

  const discountTypeOptions = useClassificationOptions(
    ClassificationGroup.BONUS_SCHEME_DISCOUNT_TYPE
  );
  const salesLimitOptions = useClassificationOptions(
    ClassificationGroup.TICKET_SALES_LIMIT_TYPE
  );
  const discountCalculationBasisOptions = useClassificationOptions(
    ClassificationGroup.DISCOUNT_CALCULATION_BASIS
  );

  return (
    <>
      <Grid item xs={12} sx={{ mb: 0.5 }}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey="details" />
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <SelectOwnerField required disabled />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="name"
          label={<TransField i18nKey="discountName" />}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <PromotionalDiscountDetailsFormInventoryClassesField />
      </Grid>
      <Grid item xs={1}>
        <SelectCurrencyField required />
      </Grid>
      <Grid item xs={1}>
        <FormControl label={<TransField i18nKey="discountAmount" />}>
          <Grid container columns={2} spacing={2} alignItems="flex-start">
            <Grid item xs={1}>
              <TextField
                name="discountPercentageAmount"
                type="number"
                validate={renderPercentageError}
                disabled={
                  promotionalDiscount?.discountPercentageAmount
                    ? false
                    : isDiscountAmountFieldDisabled({
                        discount: promotionalDiscount,
                      })
                }
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="discountTypeId"
                options={discountTypeOptions}
                disabled
                required
              />
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          name="calculationBasisId"
          label={<TransField i18nKey="discountCalculationBasis" />}
          options={discountCalculationBasisOptions}
          inline
        />
      </Grid>
      <Grid item xs={1}>
        <FormControl label={<TransField i18nKey="salesLimit" />}>
          <Grid container columns={2} spacing={2} alignItems="flex-start">
            <Grid item xs={1}>
              <TextField name="salesLimit" type="number" />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="salesLimitTypeId"
                options={salesLimitOptions}
                showEmptyOption
              />
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <PeriodField
        from={{
          name: 'validity.from',
          label: <TransField i18nKey="validFrom" />,
          required: true,
        }}
        to={{
          name: 'validity.to',
          label: <TransField i18nKey="validTo" />,
          isClearable: true,
        }}
      />
      <Grid item xs={1}>
        <TicketMinimalCostField />
      </Grid>
      <Grid item xs={1}>
        <ExcludedDatesField name="excludedTravelDays" />
      </Grid>
      <Grid item xs={1}>
        <ExcludedDatesField name="excludedPurchaseDays" />
      </Grid>
      {Boolean(promotionalDiscount?.promotions.length) && (
        <Grid item xs={1}>
          <PromotionsField name="promotions" />
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider sx={{ mx: -2 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey="appliesToTrips" />
        </Typography>
      </Grid>
      <PeriodField
        from={{
          name: 'tripDateValidity.from',
          label: <TransField i18nKey="validFrom" />,
          required: true,
        }}
        to={{
          name: 'tripDateValidity.to',
          label: <TransField i18nKey="validTo" />,
          isClearable: true,
        }}
      />
    </>
  );
};
