import type { FC } from 'react';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransField } from 'i18n/trans/field';
import { SelectField, SelectFieldProps } from '@fleet/shared';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

interface SelectOwnerFieldProps
  extends Partial<SelectFieldProps<number, false>> {}
export const SelectOwnerField: FC<SelectOwnerFieldProps> = (props) => {
  const businessEntities = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  return (
    <SelectField
      name="ownerId"
      label={<TransField i18nKey="owner" />}
      options={businessEntities}
      defaultValue={currentBusinessEntityId}
      {...props}
    />
  );
};
