import { Grid } from '@mui/material';
import {
  Accordion,
  AccordionPanel,
  ConditionField,
  RadioGroupField,
  useLocale,
} from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { TransField } from 'i18n/trans/field';
import { ToggleButtonGroupField } from '@fleet/shared/form';
import { useMemo } from 'react';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';

const useStyles = makeStyles(
  (theme) => ({
    accordion: {
      '& .MuiPaper-root': {
        margin: `0 ${theme.spacing(-2)}`,
        padding: `0 ${theme.spacing(2)}`,
        background: theme.palette.background.default,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
      },
      '& .MuiAccordionDetails-root': {
        paddingTop: theme.spacing(2),
      },
    },
  }),
  {
    name: 'PromotionalDiscountDetailsFormAdvanced',
  }
);

export const PromotionalDiscountDetailsFormAdvanced = () => {
  const classes = useStyles();

  const allOption = useMemo(
    () => ({
      value: '',
      label: <TransField i18nKey="all" />,
    }),
    []
  );
  const journeyDirectionClassificationOptions = useClassificationOptions(
    ClassificationGroup.JOURNEY_DIRECTION
  );
  const journeyWayClassificationOptions = useClassificationOptions(
    ClassificationGroup.JOURNEY_WAY
  );
  const journeyWayOptions = useMemo(
    () => [allOption, ...journeyWayClassificationOptions],
    [allOption, journeyWayClassificationOptions]
  );
  const journeyWayTypeClassificationOptions = useClassificationOptions(
    ClassificationGroup.JOURNEY_WAY_TYPE
  );
  const journeyWayTypeOptions = useMemo(
    () => [allOption, ...journeyWayTypeClassificationOptions],
    [allOption, journeyWayTypeClassificationOptions]
  );
  const journeyTypeClassificationOptions = useClassificationOptions(
    ClassificationGroup.JOURNEY_TYPE
  );
  const journeyTypeOptions = useMemo(
    () => [allOption, ...journeyTypeClassificationOptions],
    [allOption, journeyTypeClassificationOptions]
  );

  const { localize } = useLocale();
  const validOnDayOptions = useMemo(
    () =>
      [
        [1, 'Monday'],
        [2, 'Tuesday'],
        [3, 'Wednesday'],
        [4, 'Thursday'],
        [5, 'Friday'],
        [6, 'Saturday'],
        [0, 'Sunday'],
      ].map(([day, value]) => ({
        value,
        label: localize?.day(day, { width: 'abbreviated' }),
      })),
    [localize]
  );

  return (
    <Grid item xs={12} sx={{ pt: '32px !important' }}>
      <Accordion controlled>
        <AccordionPanel
          id="advanced"
          defaultExpanded
          summary="Advanced"
          className={classes.accordion}
        >
          <Grid container columns={4} spacing={2}>
            <Grid item xs={1}>
              <RadioGroupField
                name="isPublic"
                label={<TransField i18nKey="isPublic" />}
                options="BOOL_ONLY"
                inline
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                name="journeyWayId"
                label={<TransField i18nKey="appliesToJourneys" />}
                options={journeyWayOptions}
                inline
              />
            </Grid>
            <ConditionField when="journeyWayId" is="JOURNEY_WAY.ROUND_TRIP">
              <Grid item xs={1}>
                <RadioGroupField
                  name="journeyDirectionId"
                  label={<TransField i18nKey="appliesToJourneyDirection" />}
                  options={journeyDirectionClassificationOptions}
                  inline
                />
              </Grid>
            </ConditionField>
            <Grid item xs={1}>
              <RadioGroupField
                name="journeyWayTypeId"
                label={<TransField i18nKey="appliesToJourneyWays" />}
                options={journeyWayTypeOptions}
                inline
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                name="journeyTypeId"
                label={<TransField i18nKey="appliesToJourneyTypes" />}
                options={journeyTypeOptions}
                inline
              />
            </Grid>
            <Grid item xs={2}>
              <ToggleButtonGroupField
                name="validOnWeekday"
                label={<TransField i18nKey="validOnDays" />}
                options={validOnDayOptions}
                multiple
              />
            </Grid>
          </Grid>
        </AccordionPanel>
      </Accordion>
    </Grid>
  );
};
