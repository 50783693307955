import { createTrans, TransKeys } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  ageFromTo: <Trans i18nKey="field.ageFromTo" defaults="Age (from-to)" />,
  all: <Trans i18nKey="field.all" defaults="All" />,
  appliesToJourneyDirection: (
    <Trans
      i18nKey="field.appliesToJourneyDirection"
      defaults="Applies to journey direction"
    />
  ),
  appliesToJourneyTypes: (
    <Trans
      i18nKey="field.appliesToJourneyTypes"
      defaults="Applies to International / Domestic trips"
    />
  ),
  appliesToJourneyWays: (
    <Trans
      i18nKey="field.appliesToJourneyWays"
      defaults="Applies to single or multi-leg journey ways"
    />
  ),
  appliesToJourneys: (
    <Trans i18nKey="field.appliesToJourneys" defaults="Applies to journeys" />
  ),
  assigned: <Trans i18nKey="field.assigned" defaults="Assigned" />,
  bookingNumber: (
    <Trans i18nKey="field.bookingNumber" defaults="Booking number" />
  ),
  bookingRestriction: (
    <Trans i18nKey="field.bookingRestriction" defaults="Booking restriction" />
  ),
  canValueBeSetOnCreation: (
    <Trans
      i18nKey="field.canValueBeSetOnCreation"
      defaults="Value can be set on creation"
    />
  ),
  cancelledBy: <Trans i18nKey="field.cancelledBy" defaults="Cancelled by" />,
  cancelledOn: <Trans i18nKey="field.cancelledOn" defaults="Cancelled on" />,
  carrier: <Trans i18nKey="field.carrier" defaults="Carrier" />,
  category: <Trans i18nKey="field.category" defaults="Category" />,
  city: <Trans i18nKey="field.city" defaults="City" />,
  code: <Trans i18nKey="field.code" defaults="Code" />,
  columnNo: <Trans i18nKey="field.columnNo" defaults="Column no." />,
  comfortLevels: (
    <Trans i18nKey="field.comfortLevels" defaults="Comfort levels" />
  ),
  comment: <Trans i18nKey="field.comment" defaults="Comment" />,
  contacts: <Trans i18nKey="field.contacts" defaults="Contacts" />,
  copyDataFromConnectedFareTypes: (
    <Trans
      i18nKey="field.copyDataFromConnectedFareTypes"
      defaults="Copy data from connected fare types:"
    />
  ),
  country: <Trans i18nKey="field.country" defaults="Country" />,
  countryFrom: <Trans i18nKey="field.countryFrom" defaults="Country from" />,
  countryTo: <Trans i18nKey="field.countryTo" defaults="Country to" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  createdBy: <Trans i18nKey="field.createdBy" defaults="Created by" />,
  createdOn: <Trans i18nKey="field.createdOn" defaults="Created on" />,
  currency: <Trans i18nKey="field.currency" defaults="Currency" />,
  description: <Trans i18nKey="field.description" defaults="Description" />,
  destinationStops: (
    <Trans i18nKey="field.destinationStops" defaults="Destination stops" />
  ),
  discount: <Trans i18nKey="field.discount" defaults="Discount" />,
  discountAmount: (
    <Trans i18nKey="field.discountAmount" defaults="Discount amount" />
  ),
  discountCalcType: (
    <Trans
      i18nKey="field.discountCalcType"
      defaults="Discount calculation type"
    />
  ),
  discountCalculationBasis: (
    <Trans
      i18nKey="field.discountCalculationBasis"
      defaults="Discount calculation basis"
    />
  ),
  discountName: <Trans i18nKey="field.discountName" defaults="Discount name" />,
  discountSubtype: (
    <Trans i18nKey="field.discountSubtype" defaults="Discount subtype" />
  ),
  discountType: <Trans i18nKey="field.discountType" defaults="Discount type" />,
  discountWithPercentage: (
    <Trans i18nKey="field.discountWithPercentage" defaults="Discount (%)" />
  ),
  displaySelectedStops: (
    <Trans
      i18nKey="field.displaySelectedStops"
      defaults="Display selected stops"
    />
  ),
  distanceFaresActive: (
    <Trans
      i18nKey="field.distanceFaresActive.label"
      defaults="Distance fares active"
    />
  ),
  'distanceFaresActive.helper': (
    <Trans
      i18nKey="field.distanceFaresActive.helper"
      defaults="View distance fares"
    />
  ),
  distanceFrom: (
    <Trans i18nKey="field.distanceFrom" defaults="Distance from (from-to)" />
  ),
  distanceTo: (
    <Trans i18nKey="field.distanceTo" defaults="Distance to (from-to)" />
  ),
  excludedDateFieldCantBeEmpty: (
    <Trans
      i18nKey="field.excludedDateFieldCantBeEmpty"
      defaults="Excluded date field cant be empty"
    />
  ),
  excludedPurchaseDays: (
    <Trans
      i18nKey="field.excludedPurchaseDays"
      defaults="Excluded purchase dates"
    />
  ),
  excludedTravelDays: (
    <Trans
      i18nKey="field.excludedTravelDays"
      defaults="Excluded travel dates"
    />
  ),
  externalReference: (
    <Trans i18nKey="field.externalReference" defaults="External reference" />
  ),
  fare: <Trans i18nKey="field.fare" defaults="Fare" />,
  fareCategory: <Trans i18nKey="field.fareCategory" defaults="Fare category" />,
  fareDirection: (
    <Trans i18nKey="field.fareDirection.label" defaults="Fare direction" />
  ),
  'fareDirection.bidirectional': (
    <Trans
      i18nKey="field.fareDirection.option.bidirectional"
      defaults="Bidirectional"
    />
  ),
  'fareDirection.unidirectional': (
    <Trans
      i18nKey="field.fareDirection.option.unidirectional"
      defaults="Unidirectional"
    />
  ),
  fareModel: <Trans i18nKey="field.fareModel" defaults="Fare model" />,
  field: <Trans i18nKey="field.field.label" defaults="Field" />,
  'field.all': <Trans i18nKey="field.field.option.all" defaults="All fields" />,
  'field.empty': <Trans i18nKey="field.field.option.empty" defaults="Empty" />,
  'field.filled': (
    <Trans i18nKey="field.field.option.filled" defaults="Filled" />
  ),
  'field.selected': (
    <Trans i18nKey="field.field.option.selected" defaults="Selected" />
  ),
  'field.unselected': (
    <Trans i18nKey="field.field.option.unselected" defaults="Unselected" />
  ),
  fixedFaresActive: (
    <Trans
      i18nKey="field.fixedFaresActive.label"
      defaults="Fixed fares active"
    />
  ),
  'fixedFaresActive.helper': (
    <Trans
      i18nKey="field.fixedFaresActive.helper"
      defaults="View fixed fares"
    />
  ),
  hasVouchers: <Trans i18nKey="field.hasVouchers" defaults="Has vouchers" />,
  inactiveHint: <Trans i18nKey="field.inactiveHint" defaults="(inactive)" />,
  inventoryClass: (
    <Trans i18nKey="field.inventoryClass" defaults="Inventory class" />
  ),
  inventoryClasses: (
    <Trans i18nKey="field.inventoryClasses" defaults="Inventory classes" />
  ),
  isActive: <Trans i18nKey="field.isActive" defaults="Is active" />,
  isCancelled: <Trans i18nKey="field.isCancelled" defaults="Is cancelled" />,
  isChangeable: <Trans i18nKey="field.isChangeable" defaults="Is changeable" />,
  isManuallySelectable: (
    <Trans
      i18nKey="field.isManuallySelectable"
      defaults="Manually selectable"
    />
  ),
  isPublic: <Trans i18nKey="field.isPublic" defaults="Is public" />,
  isRefundable: <Trans i18nKey="field.isRefundable" defaults="Is refundable" />,
  language: <Trans i18nKey="field.language" defaults="Language" />,
  mapName: <Trans i18nKey="field.mapName" defaults="Map name" />,
  matrixView: <Trans i18nKey="field.matrixView" defaults="Matrix view" />,
  maxNoOfPayments: (
    <Trans i18nKey="field.maxNoOfPayments" defaults="Max nr of payment" />
  ),
  maxNoOfTotalTickets: (
    <Trans
      i18nKey="field.maxNoOfTotalTickets"
      defaults="Max nr of total tickets"
    />
  ),
  minimalCostFieldsCantBeEmpty: (
    <Trans
      i18nKey="field.minimalCostFieldsCantBeEmpty"
      defaults="Minimal cost fields cant be empty"
    />
  ),
  name: <Trans i18nKey="field.name" defaults="Name" />,
  no: <Trans i18nKey="field.no" defaults="No" />,
  nominalPrice: <Trans i18nKey="field.nominalPrice" defaults="Nominal price" />,
  nominalValue: (
    <Trans
      i18nKey="field.nominalValue"
      defaults="Nominal value ({{currency}})"
    />
  ),
  operatingTimezone: (
    <Trans i18nKey="field.operatingTimezone" defaults="Operating timezone" />
  ),
  operator: <Trans i18nKey="field.operator.label" defaults="Operator" />,
  'operator.add': (
    <Trans i18nKey="field.operator.option.add" defaults="+ (add)" />
  ),
  'operator.assign': (
    <Trans i18nKey="field.operator.option.assign" defaults="= (assign)" />
  ),
  'operator.multiply': (
    <Trans i18nKey="field.operator.option.multiply" defaults="* (multiply)" />
  ),
  'operator.subtract': (
    <Trans i18nKey="field.operator.option.subtract" defaults="- (subtract)" />
  ),
  orderBy: <Trans i18nKey="field.orderBy" defaults="Order by" />,
  originDestinationFaresActive: (
    <Trans
      i18nKey="field.originDestinationFaresActive.label"
      defaults="Origin-Destination fares active"
    />
  ),
  'originDestinationFaresActive.helper': (
    <Trans
      i18nKey="field.originDestinationFaresActive.helper"
      defaults="View Origin-Destination fares"
    />
  ),
  originStops: <Trans i18nKey="field.originStops" defaults="Origin stops" />,
  owner: <Trans i18nKey="field.owner" defaults="Owner" />,
  passengerType: (
    <Trans i18nKey="field.passengerType" defaults="Passenger type" />
  ),
  passengerTypes: (
    <Trans i18nKey="field.passengerTypes" defaults="Passenger types" />
  ),
  precisionOfTicketPrice: (
    <Trans
      i18nKey="field.precisionOfTicketPrice"
      defaults="Precision of ticket price"
    />
  ),
  price: <Trans i18nKey="field.price" defaults="Price" />,
  printoutTheme: (
    <Trans i18nKey="field.printoutTheme" defaults="Voucher printout theme" />
  ),
  priority: <Trans i18nKey="field.priority" defaults="Priority" />,
  promotions: <Trans i18nKey="field.promotions" defaults="Promotions" />,
  remainingValue: (
    <Trans i18nKey="field.remainingValue" defaults="Remaining value" />
  ),
  required: <Trans i18nKey="field.required" defaults="Field is required" />,
  requiredCommentType: (
    <Trans
      i18nKey="field.requiredCommentType"
      defaults="Required comment type"
    />
  ),
  requiredSeatProperty: (
    <Trans
      i18nKey="field.requiredSeatProperty"
      defaults="Required seat property"
    />
  ),
  roundingRule: (
    <Trans i18nKey="field.roundingRule.label" defaults="Rounding rule" />
  ),
  'roundingRule.HIGHER': (
    <Trans i18nKey="field.roundingRule.option.HIGHER" defaults="Higher" />
  ),
  'roundingRule.LOWER': (
    <Trans i18nKey="field.roundingRule.option.LOWER" defaults="Lower" />
  ),
  'roundingRule.ROUND': (
    <Trans i18nKey="field.roundingRule.option.ROUND" defaults="Round" />
  ),
  rowNo: <Trans i18nKey="field.rowNo" defaults="Row no." />,
  salesChannels: (
    <Trans i18nKey="field.salesChannels" defaults="Sales channels" />
  ),
  salesLimit: <Trans i18nKey="field.salesLimit" defaults="Sales limit" />,
  sameValue: (
    <Trans i18nKey="field.sameValue" defaults="Same value as {{field}}" />
  ),
  seatReservationNotAllowed: (
    <Trans
      i18nKey="field.seatReservationNotAllowed"
      defaults="Seat reservation not allowed"
    />
  ),
  shortDescription: (
    <Trans i18nKey="field.shortDescription" defaults="Short description" />
  ),
  showOnlyValidMaps: (
    <Trans i18nKey="field.showOnlyValidMaps" defaults="Show only valid maps" />
  ),
  showOnlyValidPricelists: (
    <Trans
      i18nKey="field.showOnlyValidPrices"
      defaults="Show only valid pricelists"
    />
  ),
  stopName: <Trans i18nKey="field.stopName" defaults="Stop name" />,
  stops: <Trans i18nKey="field.stops" defaults="Stops" />,
  street: <Trans i18nKey="field.street" defaults="Street" />,
  streetAndHouseNumber: (
    <Trans
      i18nKey="field.streetAndHouseNumber"
      defaults="Street, house number"
    />
  ),
  templateName: <Trans i18nKey="field.templateName" defaults="Template Name" />,
  ticketMinimalCost: (
    <Trans
      i18nKey="field.ticketMinimalCost"
      defaults="Allowed currencies, minimal cost"
    />
  ),
  ticketNumber: <Trans i18nKey="field.ticketNumber" defaults="Ticket number" />,
  to: <Trans i18nKey="field.to" defaults="to" />,
  translations: <Trans i18nKey="field.translations" defaults="Translations" />,
  travelAccount: (
    <Trans
      i18nKey="field.travelAccount"
      defaults="Travel account / Compensation name"
    />
  ),
  usedAmount: (
    <Trans i18nKey="field.usedAmount" defaults="Used amount ({{currency}})" />
  ),
  usedPayments: (
    <Trans i18nKey="field.usedPayments" defaults="Used payments / max" />
  ),
  usedProducts: (
    <Trans i18nKey="field.usedProducts" defaults="Used products / max" />
  ),
  validFares: (
    <Trans i18nKey="field.validFares" defaults="Show only valid fares" />
  ),
  validFrom: <Trans i18nKey="field.validFrom" defaults="Valid from" />,
  validFromRange: (
    <Trans i18nKey="field.validFromRange" defaults="Valid from (from-to)" />
  ),
  validFromTo: <Trans i18nKey="field.validFromTo" defaults="Valid (from-to)" />,
  validOnDays: <Trans i18nKey="field.validOnDays" defaults="Valid on days" />,
  validTimeZone: (
    <Trans i18nKey="field.validTimeZone" defaults="Valid time zone" />
  ),
  validTo: <Trans i18nKey="field.validTo" defaults="Valid to" />,
  validToRange: (
    <Trans i18nKey="field.validToRange" defaults="Valid to (from-to)" />
  ),
  validUntil: <Trans i18nKey="field.validUntil" defaults="Valid until" />,
  validityPeriod: (
    <Trans i18nKey="field.validityPeriod" defaults="Validity period" />
  ),
  validityPeriodInterpretation: (
    <Trans
      i18nKey="field.validityPeriodInterpretation"
      defaults="Validity period interpretation"
    />
  ),
  value: <Trans i18nKey="field.value" defaults="Value" />,
  vatDirective: <Trans i18nKey="field.vatDirective" defaults="VAT directive" />,
  vatRate: <Trans i18nKey="field.vatRate" defaults="VAT %" />,
  voucherCode: <Trans i18nKey="field.voucherCode" defaults="Voucher code" />,
  voucherTemplate: (
    <Trans i18nKey="field.voucherTemplate" defaults="Voucher template" />
  ),
  voucherTemplates: (
    <Trans i18nKey="field.voucherTemplates" defaults="Voucher templates" />
  ),
  voucherType: <Trans i18nKey="field.voucherType" defaults="Voucher type" />,
  voucherUsageType: (
    <Trans i18nKey="field.voucherUsageType" defaults="Voucher usage type" />
  ),
  yes: <Trans i18nKey="field.yes" defaults="Yes" />,
  yieldedMultilegDistancePricing: (
    <Trans
      i18nKey="field.yieldedMultilegDistancePricing"
      defaults="Yielded multi-leg distance pricing "
    />
  ),
  zoneA: <Trans i18nKey="field.zoneA" defaults="Zone A" />,
  zoneB: <Trans i18nKey="field.zoneB" defaults="Zone B" />,
  zoneMap: <Trans i18nKey="field.zoneMap" defaults="Zone map" />,
  zoneMapFaresActive: (
    <Trans
      i18nKey="field.zoneMapFaresActive.label"
      defaults="Zone map fares active"
    />
  ),
  'zoneMapFaresActive.helper': (
    <Trans
      i18nKey="field.zoneMapFaresActive.helper"
      defaults="View zone map fares"
    />
  ),
  zoneName: <Trans i18nKey="field.zoneName" defaults="Zone name" />,
});

export type TransFieldKeys = TransKeys<typeof TransField>;
