import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { Grid } from '@mui/material';
import {
  FormField,
  PeriodField,
  SelectField,
  TextField,
  useFormContext,
  makeClassificationOptions,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { Link, useParams } from 'react-router-dom';
import { SelectOwnerField } from 'components/SelectOwnerField';
import { SelectCurrencyField } from 'components/SelectCurrencyField';
import { ReadOnlyField } from '@fleet/shared/mui/ReadOnlyField';
import { Option } from '@fleet/shared/dto/option';
import { fetchZoneMaps } from 'features/zoneMap/zoneMapService';

interface PriceFormProps {}

export const PriceForm: FC<PriceFormProps> = ({ children }) => {
  const { action } = useParams<{ action?: 'create' | 'edit' }>();
  const isEdit = useMemo(() => action === 'edit', [action]);
  const isAddOrEdit = useMemo(() => Boolean(action), [action]);
  const { getState, change } = useFormContext();
  const values = getState().values;
  const [zoneMapOptions, setZoneMapOptions] = useState<Array<Option<string>>>(
    []
  );

  const fareCategories = useClassificationOptions(
    ClassificationGroup.FARE_CATEGORY
  );

  const fetchZoneMapOptions = useCallback(async (ownerId) => {
    setZoneMapOptions(makeClassificationOptions(await fetchZoneMaps(ownerId)));
  }, []);

  useEffect(() => {
    if (values.ownerId) {
      fetchZoneMapOptions(values.ownerId);

      if (action === 'create') {
        change('inventoryClasses');
        change('zoneMapId');
      }
    }
  }, [action, change, fetchZoneMapOptions, values.ownerId]);

  return (
    <Grid container columns={5} spacing={2}>
      <Grid item xs={1}>
        <TextField
          name="name"
          label={<TransField i18nKey="name" />}
          required={isAddOrEdit}
        />
      </Grid>
      {!isAddOrEdit && (
        <Grid item xs={1}>
          <SelectOwnerField />
        </Grid>
      )}
      <Grid item xs={1}>
        {isEdit ? (
          <FormField
            name="zoneMapId"
            render={({ input: { value: zoneMapId } }) => (
              <ReadOnlyField
                label={<TransField i18nKey="zoneMap" />}
                value={
                  <Link to={`/zone-fares/maps/edit/${zoneMapId}`}>
                    {
                      zoneMapOptions.find(({ value }) => value === zoneMapId)
                        ?.label
                    }
                  </Link>
                }
              />
            )}
          />
        ) : (
          <SelectField
            options={zoneMapOptions}
            name="zoneMapId"
            label={<TransField i18nKey="zoneMap" />}
            required={isAddOrEdit}
            showEmptyOption={!isAddOrEdit}
          />
        )}
      </Grid>
      <Grid item xs={1}>
        <SelectCurrencyField
          required={isAddOrEdit}
          showEmptyOption={!isAddOrEdit}
        />
      </Grid>
      <PeriodField
        from={{
          name: 'validity.from',
          label: <TransField i18nKey="validFrom" />,
          required: isAddOrEdit,
          isClearable: !isAddOrEdit,
        }}
        to={{
          name: 'validity.to',
          label: <TransField i18nKey="validTo" />,
          isClearable: true,
        }}
      />
      <Grid item xs={1}>
        <SelectField
          options={fareCategories}
          name="fareCategoryId"
          label={<TransField i18nKey="fareCategory" />}
          required={isAddOrEdit}
          showEmptyOption={!isAddOrEdit}
        />
      </Grid>
      {children}
    </Grid>
  );
};
