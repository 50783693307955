import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { Grid, Stack } from '@mui/material';
import {
  PeriodField,
  SelectField,
  TextField,
  useFormContext,
  makeClassificationOptions,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { SelectOwnerField } from 'components/SelectOwnerField';
import { TicketMinimalCostField } from 'components/TicketMinimalCostField';
import { Option } from '@fleet/shared/dto/option';
import { fetchInventoryClasses } from 'features/classification/classificationService';
import { useParams } from 'react-router-dom';

interface PassengerTypeDiscountsFormProps {
  mode?: 'search' | 'manage';
}

export const PassengerTypeDiscountsFields: FC<PassengerTypeDiscountsFormProps> =
  ({ mode = 'manage' }) => {
    const isEditing = mode === 'manage';
    const { action } = useParams<{ action?: 'create' | 'edit' }>();
    const discountTypeOptions = useClassificationOptions(
      ClassificationGroup.BONUS_SCHEME_DISCOUNT_TYPE
    );
    const { getState, change } = useFormContext();
    const values = getState().values;

    const [inventoryClassOptions, setInventoryClassOptions] = useState<
      Array<Option<string>>
    >([]);

    const fetchInventoryClassOptions = useCallback(async (ownerId) => {
      setInventoryClassOptions(
        makeClassificationOptions(await fetchInventoryClasses(ownerId))
      );
    }, []);

    useEffect(() => {
      if (values.ownerId) {
        fetchInventoryClassOptions(values.ownerId);

        if (action === 'create') {
          change('inventoryClasses');
        }
      }
    }, [change, fetchInventoryClassOptions, values.ownerId, action]);

    return (
      <>
        <Grid item xs={1}>
          <SelectOwnerField required={isEditing} disabled={isEditing} />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="name"
            label={<TransField i18nKey="discountName" />}
            required={isEditing}
          />
        </Grid>
        {isEditing && (
          <Grid item xs={1}>
            <SelectField
              name="discountTypeId"
              label={<TransField i18nKey="discountCalcType" />}
              options={discountTypeOptions}
              required={isEditing}
              showEmptyOption={!isEditing}
              disabled
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <Stack flexDirection="row">
            <TextField
              name="discountPercentageAmount"
              label={<TransField i18nKey="discountWithPercentage" />}
              required={isEditing}
              type="number"
              fullWidth
            />
          </Stack>
        </Grid>
        {isEditing ? (
          <>
            <Grid item xs={1}>
              <TicketMinimalCostField />
            </Grid>
            <PeriodField
              from={{
                name: 'validity.from',
                label: <TransField i18nKey="validFrom" />,
                required: true,
              }}
              to={{
                name: 'validity.to',
                label: <TransField i18nKey="validTo" />,
                required: true,
              }}
            />
            <Grid item xs={1}>
              <SelectField
                options={inventoryClassOptions}
                name="inventoryClasses"
                label={<TransField i18nKey="inventoryClasses" />}
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="comment"
                multiline
                label={<TransField i18nKey="comment" />}
              />
            </Grid>
          </>
        ) : (
          <PeriodField
            from={{
              name: 'validityFrom',
              label: <TransField i18nKey="validFromRange" />,
              selectsRange: true,
              isClearable: true,
            }}
            to={{
              name: 'validityTo',
              label: <TransField i18nKey="validToRange" />,
              selectsRange: true,
              isClearable: true,
            }}
          />
        )}
      </>
    );
  };
