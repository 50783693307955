import { filterByKeyword } from 'helpers/filter';
import { TransField } from 'i18n/trans/field';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { Pagination } from '@fleet/shared/dto/pagination';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { LineNetworkStop } from 'dto/fareModel';
import { TransTableHead } from 'i18n/trans/table';
import qs from 'qs';
import {
  CardContent,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Loadable,
  Table,
  TableColumns,
  api,
  formSubmit,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useTable,
  UseTableOptions,
} from 'react-table';
import {
  FareModelStopsSelectForm,
  StopsSearchParams,
} from 'routes/FareModels/FareModelStopsSelect/FareModelStopsSelectForm';
import type { Config as FormConfig } from 'final-form';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';
import _xor from 'lodash/xor';
import { TransAlert } from 'i18n/trans/alert';

export type FareModelStopsSelectTableRow = {
  id: LineNetworkStop['id'];
} & Exclude<Partial<LineNetworkStop>, 'id'>;
export interface FareModelStopsSelectTableProps {
  selectedStops: Array<FareModelStopsSelectTableRow>;
  stops: Pagination<FareModelStopsSelectTableRow>;
  setStops: Dispatch<SetStateAction<Pagination<FareModelStopsSelectTableRow>>>;
  selectedRowIds?: Record<string, boolean>;
  onChange(add: boolean, rows: Array<FareModelStopsSelectTableRow>): void;
  allowSearch: boolean;
}

interface StopsSearchQueryParams
  extends Partial<StopsSearchParams>,
    Partial<PaginationParams> {}

export const FareModelStopsSelectTable: FC<FareModelStopsSelectTableProps> = ({
  selectedStops,
  stops,
  setStops,
  selectedRowIds = {},
  onChange,
  allowSearch,
}) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<StopsSearchQueryParams>({});
  const [selectedStopsPagination, setSelectedStopsPagination] = useState(
    () => ({
      offset: 0,
      limit: 10,
      page: 0,
    })
  );
  const { items, offset, totalCount } = stops;
  const { limit = 10 } = filters;

  const [view, setView] = useState<'search' | 'selected'>(() =>
    Boolean(selectedStops.length) ? 'selected' : 'search'
  );
  const columns = useMemo<TableColumns<FareModelStopsSelectTableRow>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'countryName',
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        accessor: 'countyName',
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        accessor: 'cityName',
        Header: <TransTableHead i18nKey="city" />,
      },
      {
        accessor: 'streetAddress',
        Header: <TransTableHead i18nKey="streetAndHouseNumber" />,
      },
    ],
    []
  );

  const data = useMemo(() => items, [items]);
  const selectedStopsData = useMemo(() => {
    const { name, countryId, countyName, cityName, streetAddress } = filters;
    const { offset, limit } = selectedStopsPagination;
    if (allowSearch)
      return {
        data: selectedStops.slice(offset, offset + limit),
        total: selectedStops.length,
      };
    const filtered = selectedStops.filter(
      (stop) =>
        (name ? filterByKeyword(name)(stop.name) : true) &&
        (countryId ? stop.countryId === countryId : true) &&
        (countyName ? filterByKeyword(countyName)(stop.countyName) : true) &&
        (cityName ? filterByKeyword(cityName)(stop.cityName) : true) &&
        (streetAddress
          ? filterByKeyword(streetAddress)(stop.streetAddress)
          : true)
    );
    return {
      data: filtered.slice(offset, offset + limit),
      total: filtered.length,
    };
  }, [allowSearch, selectedStops, filters, selectedStopsPagination]);

  const handleSelectedStopsPageChange = useCallback(
    async ({ offset = 0, limit = 10 }: PaginationParams) => {
      setSelectedStopsPagination((old) => ({
        ...old,
        offset,
        limit,
        page: offset / limit,
      }));
    },
    []
  );

  const handleSearch = useCallback(
    async (params: StopsSearchQueryParams) => {
      setLoading(true);
      setFilters((old) => ({ ...old, ...params }));
      const { data } = await api.get(
        `/stops${qs.stringify(params, { addQueryPrefix: true })}`,
        {
          baseURL: process.env.REACT_APP_API_LM,
        }
      );
      setStops(data);
      setLoading(false);
      setView('search');
    },
    [setStops]
  );

  const handleSubmit = useCallback<FormConfig<StopsSearchParams>['onSubmit']>(
    (values, form) => {
      const required = _xor(form.getRegisteredFields(), ['countryId']);
      if (allowSearch && _isEmpty(_filter(_pick(values, required), Boolean)))
        return required.reduce(
          (acc, name) => ({
            ...acc,
            [name]: <TransAlert i18nKey="fillAtLeastOne" />,
          }),
          {}
        );
      return formSubmit(async () => {
        const params = { ...filters, ...values };
        if (allowSearch) await handleSearch(params);
        else {
          setSelectedStopsPagination((old) => ({
            ...old,
            offset: 0,
            page: 0,
          }));
          setFilters(values);
        }
      });
    },
    [allowSearch, filters, handleSearch]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      const params = { ...filters, ...paginationParams };
      await handleSearch(params);
      setFilters(params);
    },
    [filters, handleSearch]
  );

  const page = useMemo(() => offset / limit, [offset, limit]);
  const getRowId = useCallback(
    (row: FareModelStopsSelectTableRow) => `${row.id}`,
    []
  );
  const stateReducer = useCallback<
    Required<UseTableOptions<FareModelStopsSelectTableRow>>['stateReducer']
  >(
    (newState, action, previousState, instance) => {
      switch (action.type) {
        case 'toggleRowSelected':
          const { original } = instance!.flatRows.find(
            ({ id }) => id === action.id
          )!;
          onChange(action.value, [original]);
          break;
        case 'toggleAllRowsSelected':
          onChange(
            action.value,
            instance!.page.map(({ original }) => original)
          );
          break;
        default:
          break;
      }

      return newState;
    },
    [onChange]
  );

  const table = useTable<FareModelStopsSelectTableRow>(
    {
      data,
      columns,
      getRowId,
      manualPagination: true,
      pageCount: -1,
      total: totalCount,
      onPageChange: handlePageChange,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
        expanded: selectedRowIds,
        pageIndex: page,
        pageSize: limit,
      }),
      stateReducer,
    },
    useExpanded,
    usePagination,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedStopsTable = useTable<FareModelStopsSelectTableRow>(
    {
      data: selectedStopsData.data,
      columns,
      getRowId,
      manualPagination: true,
      pageCount: -1,
      total: selectedStopsData.total,
      onPageChange: handleSelectedStopsPageChange,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
        pageIndex: selectedStopsPagination.page,
      }),
      stateReducer,
    },
    usePagination,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleChangeView = useCallback<Required<SwitchProps>['onChange']>(
    (e, checked) => {
      setView(checked ? 'selected' : 'search');
    },
    []
  );

  return (
    <Loadable loading={loading}>
      <div>
        <FareModelStopsSelectForm
          onSubmit={handleSubmit}
          allowSearch={allowSearch}
        />
        <Divider sx={{ mt: 3, mb: 2 }} />
        {allowSearch && (
          <>
            <CardContent sx={{ padding: '16px 0 0' }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle" fontWeight="700" component="div">
                  <TransSubtitle i18nKey="searchResults" />
                  {Boolean(totalCount) && (
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="qtyStops"
                        values={{ num: totalCount }}
                      />
                    </Typography>
                  )}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ mr: 1 }}
                      checked={view === 'selected'}
                      onChange={handleChangeView}
                    />
                  }
                  label={<TransField i18nKey="displaySelectedStops" />}
                />
              </Stack>
            </CardContent>
          </>
        )}
      </div>
      {view === 'search' && <Table table={table} />}
      {view === 'selected' && <Table table={selectedStopsTable} />}
    </Loadable>
  );
};
