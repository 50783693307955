import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransLabel = createTrans({
  'FARE_TYPE.ADMISSION_DEPENDANT': (
    <Trans
      i18nKey="label.FARE_TYPE.ADMISSION_DEPENDANT"
      defaults="Admission dependent"
    />
  ),
  'FARE_TYPE.DISTANCE': (
    <Trans i18nKey="label.FARE_TYPE.DISTANCE" defaults="Distance" />
  ),
  'FARE_TYPE.FIXED': <Trans i18nKey="label.FARE_TYPE.FIXED" defaults="Fixed" />,
  'FARE_TYPE.ORIGIN_DESTINATION': (
    <Trans
      i18nKey="label.FARE_TYPE.ORIGIN_DESTINATION"
      defaults="Origin-Destination"
    />
  ),
  'FARE_TYPE.THRU': <Trans i18nKey="label.FARE_TYPE.THRU" defaults="Thru" />,
  'FARE_TYPE.ZONE': (
    <Trans i18nKey="label.FARE_TYPE.ZONE" defaults="Zone map" />
  ),
  bookingNumber: (
    <Trans i18nKey="label.bookingNumber" defaults="Booking number" />
  ),
  cancelledBy: <Trans i18nKey="label.cancelledBy" defaults="Cancelled by" />,
  cancelledOn: <Trans i18nKey="label.cancelledOn" defaults="Cancelled on" />,
  carrier: <Trans i18nKey="label.carrier" defaults="Carrier" />,
  change: <Trans i18nKey="label.change" defaults="Change" />,
  code: <Trans i18nKey="label.code" defaults="Code" />,
  createdBy: <Trans i18nKey="label.createdBy" defaults="Created by" />,
  createdOn: <Trans i18nKey="label.createdOn" defaults="Created on" />,
  currency: <Trans i18nKey="label.currency" defaults="Currency" />,
  details: <Trans i18nKey="label.details" defaults="Details" />,
  discountAmount: (
    <Trans i18nKey="label.discountAmount" defaults="Discount amount" />
  ),
  discountCalculationType: (
    <Trans
      i18nKey="label.discountCalculationType"
      defaults="Discount calculation type"
    />
  ),
  discountDiscountAndCurrency: (
    <Trans i18nKey="label.discountDiscountAndCurrency" defaults="Unknown" />
  ),
  discountName: <Trans i18nKey="label.discountName" defaults="Discount name" />,
  discountSubtype: (
    <Trans i18nKey="label.discountSubType" defaults="Discount subtype" />
  ),
  discountWithPercentage: (
    <Trans i18nKey="label.discountWithPercentage" defaults="Discount (%)" />
  ),
  field: <Trans i18nKey="label.field" defaults="Field" />,
  isCancelled: <Trans i18nKey="label.isCancelled" defaults="Is cancelled" />,
  modifiedBy: <Trans i18nKey="label.modifiedBy" defaults="Modified by" />,
  modifiedOn: <Trans i18nKey="label.modifiedOn" defaults="Modified on" />,
  name: <Trans i18nKey="label.name" defaults="Name" />,
  nominalValue: <Trans i18nKey="label.nominalValue" defaults="Nominal value" />,
  originDestination: (
    <Trans i18nKey="label.originDestination" defaults="Origin-Destination" />
  ),
  promotions: <Trans i18nKey="label.promotions" defaults="Promotions" />,
  remainingValue: (
    <Trans i18nKey="label.remainingValue" defaults="Remaining value" />
  ),
  searchResults: (
    <Trans i18nKey="label.searchResults" defaults="Search results" />
  ),
  thru: <Trans i18nKey="label.thru" defaults="Thru" />,
  ticketNumber: <Trans i18nKey="label.ticketNumber" defaults="Ticket number" />,
  travelAccountCompensationName: (
    <Trans
      i18nKey="label.travelAccountCompensationName"
      defaults="Travel account compensation name"
    />
  ),
  usedAmount: <Trans i18nKey="label.usedAmount" defaults="Used amount" />,
  usedPayments: (
    <Trans i18nKey="label.usedPayments" defaults="Used payments / max" />
  ),
  usedProducts: (
    <Trans i18nKey="label.usedProducts" defaults="Used products / max" />
  ),
  validFrom: <Trans i18nKey="label.validFrom" defaults="Valid from" />,
  validFromTo: <Trans i18nKey="label.validFromTo" defaults="Valid (from-to)" />,
  validUntil: <Trans i18nKey="label.validUntil" defaults="Valid until" />,
  voucher: <Trans i18nKey="label.voucher" defaults="Voucher" />,
  voucherCode: <Trans i18nKey="label.voucherCode" defaults="Voucher code" />,
  voucherOwner: <Trans i18nKey="label.voucherOwner" defaults="Voucher owner" />,
  voucherTemplate: (
    <Trans i18nKey="label.voucherTemplate" defaults="Voucher template" />
  ),
  voucherType: <Trans i18nKey="label.vouchers" defaults="Voucher type" />,
  zoneMap: <Trans i18nKey="label.zoneMap" defaults="Zone map" />,
});
