import { CoTaxationGroup } from 'dto/coTaxationGroup';
import { createAsyncThunk } from 'store/utils';
import type { ClassificationGroupItem } from 'dto/classification';
import {
  BusinessEntity,
  City,
  Country,
  County,
  InventoryClass,
  PrintoutTheme,
  STANDARD_CLASSIFICATION_GROUPS,
  ZoneMap,
} from 'dto/classification';
import type { PassengerType } from 'dto/passengerType';
import qs from 'qs';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import {
  fetchBusinessEntities,
  fetchInventoryClasses,
} from 'features/classification/classificationService';
import { api } from '@fleet/shared';

export const getClassificationGroups = createAsyncThunk<
  Array<ClassificationGroupItem>
>(
  'classification/groups',
  async () =>
    (
      await api.get<{ items: Array<ClassificationGroupItem> }>(
        `/classifications/groups${qs.stringify(
          {
            ids: Object.values(STANDARD_CLASSIFICATION_GROUPS),
          },
          {
            addQueryPrefix: true,
            skipNulls: true,
            arrayFormat: 'comma',
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_BD,
        }
      )
    ).data.items
);

export const getCountries = createAsyncThunk<Array<Country>>(
  'classification/countries',
  async () =>
    (
      await api.get('/countries', {
        baseURL: process.env.REACT_APP_API_BD,
      })
    ).data.items
);

export const getCounties = createAsyncThunk<Array<County>>(
  'classification/counties',
  async () =>
    (
      await api.get('/counties', {
        baseURL: process.env.REACT_APP_API_BD,
      })
    ).data.items
);

export const getCities = createAsyncThunk<Array<City>>(
  'classification/cities',
  async () =>
    (
      await api.get('/cities', {
        baseURL: process.env.REACT_APP_API_BD,
      })
    ).data.items
);

export const getCoTaxationGroups = createAsyncThunk<Array<CoTaxationGroup>>(
  'classification/coTaxationGroups',
  async () =>
    (
      await api.get('/co-taxation-groups', {
        baseURL: process.env.REACT_APP_API_OM,
      })
    ).data.items
);

export const getBusinessEntities = createAsyncThunk<Array<BusinessEntity>>(
  'classification/businessEntities',
  async (_, { getState, dispatch }) => {
    const businessEntities = await fetchBusinessEntities();

    const defaultBusinessEntity =
      businessEntities.find(({ isMain }) => isMain) || businessEntities[0];
    dispatch(
      setCurrentBusinessEntity(
        selectCurrentBusinessEntityId(getState()) || defaultBusinessEntity.id
      )
    );

    return businessEntities;
  }
);

export const getPrintoutThemes = createAsyncThunk<Array<PrintoutTheme>>(
  'classification/printoutThemes',
  async (_, { getState }) =>
    (
      await api.get(
        `/organizations/${selectCurrentBusinessEntityId(
          getState()
        )}/voucher-printout-themes`,
        {
          baseURL: process.env.REACT_APP_API_OM,
        }
      )
    ).data.items
);

export const getPassengerTypes = createAsyncThunk<Array<PassengerType>>(
  'classification/passengerTypes',
  async (_, { getState }) => {
    return (
      await api.get(
        `/passenger-types${qs.stringify(
          {
            ownerId: selectCurrentBusinessEntityId(getState()),
            limit: 100,
          },
          { addQueryPrefix: true }
        )}`
      )
    ).data.items;
  }
);

export const getZoneMaps = createAsyncThunk<Array<ZoneMap>>(
  'classification/zoneMaps',
  async () => (await api.get(`/zone-maps`)).data.items
);

export const getInventoryClasses = createAsyncThunk<Array<InventoryClass>>(
  'classification/inventoryClasses',
  async (_, { getState }) =>
    await fetchInventoryClasses(selectCurrentBusinessEntityId(getState()))
);

export const getClassifications = createAsyncThunk(
  'classification/all',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(getCities()),
      dispatch(getCoTaxationGroups()),
      dispatch(getCounties()),
      dispatch(getCountries()),
      dispatch(getInventoryClasses()),
      dispatch(getPassengerTypes()),
      dispatch(getPrintoutThemes()),
      dispatch(getZoneMaps()),
      dispatch(getClassificationGroups()),
    ]);
  }
);
