import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { TabPanel, Tabs } from '@fleet/shared';
import { TransTab } from 'i18n/trans/tab';
import { FareModelDistanceRangesTable } from 'routes/FareModels/FareModelDistances/FareModelDistanceRangesTable';
import { FareModelCommercialDistances } from 'routes/FareModels/FareModelDistances/FareModelCommercialDistances';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
      padding: theme.spacing(3),
      paddingTop: 0,
      '& > .MuiPaper-root': {
        flex: 1,
        minHeight: 0,
      },
    },
    tab: {
      height: '100%',
      padding: `0 ${theme.spacing(3)} 0`,
      '& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }),
  {
    name: 'FareModelDistanceTabs',
  }
);

interface FareModelDistanceTabsProps {}

export const FareModelDistanceTabs: FC<FareModelDistanceTabsProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs>
        <TabPanel
          className={classes.tab}
          value="distanceRanges"
          label={<TransTab i18nKey="distanceFares.distanceRanges" />}
        >
          <FareModelDistanceRangesTable />
        </TabPanel>
        <TabPanel
          className={classes.tab}
          value="commercialDistances"
          label={<TransTab i18nKey="distanceFares.commercialDistances" />}
        >
          <FareModelCommercialDistances />
        </TabPanel>
      </Tabs>
    </div>
  );
};
