import { useEffect } from 'react';
import { AppVersion, EnvironmentBanner } from '@fleet/shared';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header } from 'components/Header';
import { useAuth } from 'react-oidc-context';
import { FareModels } from 'routes/FareModels/FareModels';
import { FareModelStops } from 'routes/FareModels/FareModelStops';
import { PassengerTypeDiscounts } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscounts';
import { PriceLists } from 'routes/ZonePriceList/PriceLists';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { PassengerTypes } from 'routes/PassengerTypes/PassengerTypes';
import PromotionalDiscounts from 'routes/PromotionalDiscounts/PromotionalDiscounts';
import { CoTaxationGroups } from 'routes/CoTaxationGroups/CoTaxationGroups';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { VouchersTemplate } from 'routes/VouchersTemplate/VouchersTemplate';
import { Vouchers } from 'routes/Vouchers/Vouchers';
import { VoucherDetails } from 'routes/Vouchers/VoucherDetails';
import { ZoneMap } from 'routes/ZoneMap/ZoneMap';
import { FareModelDistances } from 'routes/FareModels/FareModelDistances';
import { PromotionalDiscountDetails } from 'routes/PromotionalDiscounts/PromotionalDiscountDetails';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import AuthWrapper from 'wrappers/AuthWrapper';
import { FareModelFixed } from 'routes/FareModels/FareModelFixed';

export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const appLoading = useSelector(appLoadingSelector);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getBusinessEntities());
    }
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && currentBusinessEntityId) {
      dispatch(getClassifications());
    }
  }, [auth.isAuthenticated, dispatch, currentBusinessEntityId]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={appLoading}>
          <EnvironmentBanner envName={process.env.REACT_APP_ENVIRONMENT!} />
          <Header />
          <Switch>
            <Route
              exact
              path="/passenger-types/:action(create|edit)?/:id?"
              component={PassengerTypes}
            />
            <Route
              exact
              path="/passenger-type-discounts/:action(create|edit)?/:id?"
              component={PassengerTypeDiscounts}
            />
            <Route
              exact
              path="/co-taxation-groups/:action(create|edit)?/:id?"
              component={CoTaxationGroups}
            />
            <Route path="/zone-fares/pricelists" component={PriceLists} />
            <Route path="/zone-fares/maps" component={ZoneMap} />
            <Route
              path="/vouchers-template/:action(create|edit)?/:id?"
              component={VouchersTemplate}
            />
            <Route exact path="/vouchers/" component={Vouchers} />
            <Route exact path="/vouchers/:id" component={VoucherDetails} />
            <Route
              path="/promotional-discounts"
              component={PromotionalDiscounts}
              exact
            />
            <Route
              path="/promotional-discounts/:action(create|edit)?/:id?"
              component={PromotionalDiscountDetails}
              exact
            />
            <Route
              exact
              path="/fare-models/:action(create|edit)?/:id?"
              component={FareModels}
            />
            <Route
              exact
              path="/fare-models/edit/:id/stops"
              component={FareModelStops}
            />
            <Route
              exact
              path="/fare-models/edit/:id/distances"
              component={FareModelDistances}
            />
            <Route
              exact
              path="/fare-models/edit/:id/fixed"
              component={FareModelFixed}
            />
          </Switch>
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
}
