import { BusinessEntity, InventoryClass } from 'dto/classification';
import qs from 'qs';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';

export const fetchInventoryClasses = async (ownerId: number) =>
  (
    await api.get<Pagination<InventoryClass>>(
      `/business-entities/${ownerId}/inventory-classes${qs.stringify(
        { limit: 100 },
        {
          addQueryPrefix: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_VM,
      }
    )
  ).data.items;

export const fetchBusinessEntities = async (params?: { RoleId: string }) =>
  (
    await api.get<{ items: BusinessEntity[] }>(
      `/users/business-entities${qs.stringify(params, {
        addQueryPrefix: true,
        arrayFormat: 'comma',
      })}`,
      {
        baseURL: process.env.REACT_APP_API_OM,
      }
    )
  ).data.items;
