import { FC, useCallback, useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { SelectField, TextField } from '@fleet/shared/form';
import { TransField } from 'i18n/trans/field';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { TransHint } from 'i18n/trans/hint';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { renderToString } from 'react-dom/server';
import { TransValidate } from 'i18n/trans/validate';
import {
  currentPassengerTypeSelector,
  passengerTypesSelector,
} from 'features/passengerType/passengerTypeSelectors';
import { RadioGroupField } from '@fleet/shared';
import { SelectOwnerField } from 'components/SelectOwnerField';

interface PassengerTypeFieldsProps {}

export const PassengerTypeFields: FC<PassengerTypeFieldsProps> = () => {
  const passengerTypes = useSelector(passengerTypesSelector);
  const passengerTypeItems = useMemo(
    () => passengerTypes?.items ?? [],
    [passengerTypes]
  );
  const currentPassengerType = useSelector(currentPassengerTypeSelector);
  const isValidityCriteriaMissing = Boolean(
    !currentPassengerType?.validityCriterias?.length
  );
  const passengerTypeCategoryOptions = useClassificationOptions(
    ClassificationGroup.PASSENGER_TYPE_CATEGORY
  );
  const passengerTypeBookingRestrictionOptions = useClassificationOptions(
    ClassificationGroup.PASSENGER_TYPE_BOOKING_RESTRICTION
  );
  const seatPropertyOptions = useClassificationOptions(
    ClassificationGroup.SEAT_PROPERTY
  );
  const commentTypeOptions = useClassificationOptions(
    ClassificationGroup.COMMENT_TYPE
  );

  const validateName = useCallback(
    (value) => {
      const namesInUse = passengerTypeItems
        .filter(({ id }) => id !== currentPassengerType?.id)
        .map(({ name }) => name);

      if (!namesInUse.includes(value)) {
        return null;
      }

      return renderToString(
        <TransValidate
          i18nKey="uniq"
          values={{
            field: renderToString(<TransField i18nKey="name" />),
          }}
        />
      );
    },
    [passengerTypeItems, currentPassengerType?.id]
  );

  return (
    <>
      <Grid item xs={1}>
        <SelectOwnerField disabled />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label={<TransField i18nKey="name" />}
          name="name"
          validate={validateName}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransField i18nKey="category" />}
          name="passengerTypeCategoryId"
          options={passengerTypeCategoryOptions}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransField i18nKey="bookingRestriction" />}
          name="passengerTypeBookingRestrictionId"
          options={passengerTypeBookingRestrictionOptions}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransField i18nKey="requiredSeatProperty" />}
          name="requiredSeatPropertyId"
          options={seatPropertyOptions}
          showEmptyOption
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransField i18nKey="requiredCommentType" />}
          name="requiredCommentTypeId"
          options={commentTypeOptions}
          showEmptyOption
        />
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          options="BOOL_ONLY"
          name="isActive"
          label={
            <Stack direction="row" alignItems="center">
              <span>
                <TransField i18nKey="isActive" />
              </span>
              {isValidityCriteriaMissing && (
                <Tooltip
                  placement="top"
                  content={<TransHint i18nKey="validityCriteriaMandatory" />}
                >
                  <Icon name="question" margin size={14} />
                </Tooltip>
              )}
            </Stack>
          }
          disabled={isValidityCriteriaMissing}
          inline
        />
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          options="BOOL_ONLY"
          name="isWithoutSeatReservation"
          label={
            <Stack direction="row" alignItems="center">
              <span>
                <TransField i18nKey="seatReservationNotAllowed" />
              </span>
              <Tooltip
                placement="top"
                content={<TransHint i18nKey="reservationNotAllowed" />}
              >
                <Icon name="question" margin size={14} />
              </Tooltip>
            </Stack>
          }
          inline
        />
      </Grid>
    </>
  );
};
